import React from 'react';
import classNames from 'classnames';

import { TranslationJSX } from 'shared/components/translation/translation.component';

interface Props {
  dataCy?: string;
  resource?: ResourceKey;
  display?: boolean;
  className?: string;
  replacements?: JSX.Element[];
  children?: React.ReactNode;
}

export const FormError: React.FunctionComponent<Props> = ({resource, dataCy, display = true, replacements, ...props}) =>
  <div aria-live="assertive" className="form-error-live-region">
    {display &&
      <div className={classNames(props.className, 'form-group', 'space-outer-top-xs', 'has-error', 'js-form-error')}>
        <div className="help-text form-error ns-icon ns-error" role="alert" {...(!!dataCy && { 'data-cy': dataCy })} >
          {Boolean(resource) && <TranslationJSX resource={resource} replacements={replacements}/>}
          {props.children}
        </div>
      </div>
    }
  </div>;
